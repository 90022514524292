<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <div class="row">
      <div class="col-sm-6">
        <h4 qid="third-parties-page-title" :class="{'mb-1': getGuide('vendor_list_page')}">
          {{ $t('maintenance.third_parties.vendors.title') }}
        </h4>
        <div>
          <span v-if="getGuide('vendor_list_page')">
            <GuideButton :guide="getGuide('vendor_list_page')"></GuideButton>
          </span>
        </div>
      </div>
      <div class="col-sm-6 text-right">
        <router-link
            to="/third-parties/create"
            class="btn btn-success float-right"
            qid="create-tp-button"
            v-if="isGranted(permissions.THIRD_PARTY_CREATE)"
        >
          <font-awesome-icon icon="plus-circle"/> {{ $t('maintenance.third_parties.vendors.create_button') }}
        </router-link>
        <b-form-input
          type="text"
          v-model="filters.keywordValue"
          class="float-right mw-300 mr-3 search-bar"
          qid="search-tp-input"
          v-on:input="debounceInput"
          :placeholder="$t('maintenance.third_parties.vendors.search_placeholder')"
        ></b-form-input>
      </div>
    </div>

    <div class="row" :class="{'table-with-filters': filterOpened}">
      <div class="col-sm-10 table-container">
        <div class="">
          <table class="table table-responsive-md table-list">
            <thead>
              <tr>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('name')"
                    qid="sort-tp-name"
                >
                  {{ $t('maintenance.third_parties.table.name') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                  </span>
                </th>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('updated_at')"
                    qid="sort-p-name"
                >
                  {{ $t('system.last_updated') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                  </span>
                </th>
                <th width="180"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="object in tableItems">
                <td>
                  <router-link
                      :to="{ name: 'contracts.third_parties.view', params: { slug: object.slug }}"
                      qid="tp-view-page-link"
                  >
                    <Avatar :object="object" size="xs" class="mr-1"></Avatar>
                    {{object.name}}
                  </router-link>
                </td>
                <td>
                  <span class="text-normal">
                    {{ object.updated_at | formatDate }}
                  </span>
                </td>
                <td align="right">
                  <VendorEditForm
                      :object="object"
                      @reloadContent="loadContent(currentPage)"
                      v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                  ></VendorEditForm>
                  <span v-if="isGranted([permissions.THIRD_PARTY_DELETE, permissions.THIRD_PARTY_DELETE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                        class="btn btn-delete"
                        qid="delete-tp-modal"
                        @click="showConfirmDeleteModal(object)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                </td>
              </tr>
              <tr v-if="!loading && !tableItems.length" qid="no-results-row" class="text-center">
                <td colspan="10">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    label="value"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
      <div class="col-sm-2 filter-container">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <label qid="filter-s-modified-from-label">
                {{ $t('activities.create.modified_by_date_from_label') }}
              </label>
              <datepicker
                  class="date-picker"
                  qid="filter-s-form-modified-from"
                  calendar-class="right"
                  @selected="debounceInput"
                  @remove="debounceInput"
                  v-model="filters.modifiedFrom"
                  :clear-button="true"
                  :placeholder="$t('activities.create.modified_by_date_from_placeholder')"
              ></datepicker>
            </b-form-group>
            <b-form-group>
              <label qid="filter-s-modified-to-label">
                {{ $t('activities.create.modified_by_date_to_label') }}
              </label>
              <datepicker
                  class="date-picker"
                  qid="filter-s-form-modified-to"
                  calendar-class="right"
                  @selected="debounceInput"
                  @remove="debounceInput"
                  v-model="filters.modifiedTo"
                  :clear-button="true"
                  :placeholder="$t('activities.create.modified_by_date_to_placeholder')"
              ></datepicker>
            </b-form-group>
            <a href="#" @click="resetFilters" class="text-right" qid="filter-tp-reset">
              {{ $t('system.reset_filters') }}
            </a>
          </div>
        </div>
      </div>
    </div>


    <!-- Delete third party confirm modal template -->
    <b-modal ref="delete-third-party" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('maintenance.third_parties.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('maintenance.third_parties.delete.description') }} {{ itemToDelete.name }}
      </h5>
      <h5 class="text-center">
        {{ $t('maintenance.third_parties.delete.are_you_sure') }}
      </h5>

      <h6 class="mt-2" v-if="contracts && contracts.length && itemToDelete && itemToDelete.id">
        {{ $t('maintenance.third_parties.delete.contracts_notice') }}
      </h6>
      <table class="table table-responsive-md" v-if="contracts && contracts.length && itemToDelete && itemToDelete.id">
        <thead>
        <tr>
          <th>
            {{ $t('maintenance.third_parties.table.name') }}
          </th>
          <th>
            {{ $t('system.last_updated') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="object in contracts" v-if="contracts && contracts.length">
          <td>
            <router-link
                :to="{ name: 'contracts.view', params: { slug: object.slug }}"
                qid="tp-view-page-link"
            >
              {{object.name}}
            </router-link>
          </td>
          <td>
            <span>
              {{ object.updated_at | formatDate }}
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideConfirmDeleteModal"
                  qid="delete-tp-cancel-button"
        >
          {{ $t('maintenance.third_parties.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteTP(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-tp-submit-button"
                  v-if="isGranted([permissions.THIRD_PARTY_DELETE, permissions.THIRD_PARTY_DELETE_OWN], itemToDelete) && (!getClient.parent || (getClient.parent && itemToDelete.partition)) && !contracts.length"
        >
          {{ $t('maintenance.third_parties.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Datepicker from 'vuejs-datepicker';
  import Avatar from '@/components/Avatar.vue';
  import moment from 'moment';
  import roleCheck from '@/util/ACL/roleCheck.js'
  import VendorEditForm from '@/views/ThirdParty/VendorEditForm.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'Vendors',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.third_parties.vendors.title'),
            active: true
          }
        ],
        tableItems: [],

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,

        itemToDelete: {},
        tpForm: {
          name: '',
          description: '',
          contact_details: '',
          type: '',
          inspection: [],
          inspection_updated_at: '',
          data_processor_agreement: [],
          dpa_updated_at: '',
          countries: [],
          client:'',
          data_objects: [],
          tags: [],
          transfers_disclosures_third_countries: [],
          third_country_contract_type: [],
          third_country_contract_date: '',
          dpa_contract_type: []
        },
        error: '',
        countries: [],
        selectedCountries: [],
        inspectionItems: [
          { name: this.$t('maintenance.third_parties.inspection.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.inspection.external_audit'), value: 'External audit' },
          { name: this.$t('maintenance.third_parties.inspection.site_visit'), value: 'Site visit' },
          { name: this.$t('maintenance.third_parties.inspection.third-party_self-audit'), value: 'Third-party self-audit' }
        ],
        dpaItems: [
          { name: this.$t('maintenance.third_parties.data_processor_agreement.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.n/a'), value: 'N/A' }
        ],
        dpaContractTypes: [
          { name: this.$t('maintenance.third_parties.dpa_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        transfersDisclosuresThirdCountries : [
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.n/a'), value: 'N/A' }
        ],
        thirdCountryContractTypes: [
          { name: this.$t('maintenance.third_parties.third_country_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        filters: {
          type: '',
          owners: [],
          tags: [],
          data_processor_agreement: '',
          dpa_updated_at: '',
          inspection: '',
          inspection_updated_at: '',
          modifiedFrom: '',
          modifiedTo: '',
          parent: 0,
          keywordValue: ''
        },
        types: [],
        typeInput: '',
        dataObjects: [],
        selectedDataObjects: [],
        loading: true,
        selectedTP: '',
        buttonDisabled: false,
        myGuides: [],
        selectedTags: [],
        tags: [],
        users: [],
        newTags: [],
        selectedItem: {},
        showTagsModal: false,
        showUsersModal: false,
        radioOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0}
        ],
        contracts: [],
        downloadForm: {
          basic_info: 1,
          owners: 0,
          data: 0,
          risk_scenarios: 0,
          risk_validation_log: 0
        },
      }
    },
    components: {
      Datepicker,
      Avatar,
      VendorEditForm,
      GuideButton
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getFilters() {
        return this.$store.state.activeFilters
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadContent()
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadContent()
      }, 400),
      goToHome() {
        this.$router.push('/')
      },
      resetFilters() {
        this.filters.type = ''
        this.filters.owners = []
        this.filters.tags = []
        this.filters.data_processor_agreement = ''
        this.filters.dpa_updated_at = ''
        this.filters.inspection = ''
        this.filters.inspection_updated_at = ''
        this.filters.modifiedFrom = ''
        this.filters.modifiedTo = ''
        this.filters.parent = 0

        this.debounceInput()
      },
      handleFilterButton(forceOpened) {
        if (!this.filterOpened) {
          this.loadTypes()
          this.loadTags();
          this.loadUsers();
        }
        if (forceOpened) {
          this.filterOpened = true
        } else {
          this.filterOpened = !this.filterOpened
        }
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      handleDownloadModal() {
        this.$refs['download-modal'].show()
      },
      closeDownloadModal() {
        this.$refs['download-modal'].hide()
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Vendor' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Vendor',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadContent();
      },
      loadContent(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;
        let formOwners = [];
        let formTags = [];

        if (this.filters.owners && this.filters.owners.length) {
          for (var i = 0; i < this.filters.owners.length; i++) {
            formOwners[i] = this.filters.owners[i].id;
          }
          this.handleFilterButton(true)
        }

        if (this.filters.tags && this.filters.tags.length) {
          for (var j = 0; j < this.filters.tags.length; j++) {
            formTags[j] = this.filters.tags[j].id;
          }
          this.handleFilterButton(true)
        }

        if (
          (this.filters.modifiedBy && this.filters.modifiedBy.id) ||
          (this.filters.type && this.filters.type.type) ||
          (this.filters.data_processor_agreement && this.filters.data_processor_agreement.value) ||
          (this.filters.inspection && this.filters.inspection.value) ||
          this.filters.parent ||
          this.filters.dpa_updated_at ||
          this.filters.inspection_updated_at ||
          this.filters.modifiedFrom ||
          this.filters.modifiedTo
        ) {
          this.handleFilterButton(true)
        }

        piincHttp.get('vendors', { params:
          {
            page: pageNumber,
            per_page: perPageNumber,
            sort: this.selectedSort !== '' ? this.selectedSort : undefined,
            order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
            search: this.filters.keywordValue !== '' ? this.filters.keywordValue : undefined,
            tags: formTags ? formTags : undefined,
            modified_from: this.filters.modifiedFrom ? this.filters.modifiedFrom : undefined,
            modified_to: this.filters.modifiedTo ? this.filters.modifiedTo : undefined,
            parent: this.getClient.parent ? 1 : undefined,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.loading = false;
          self.tableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;

          if (!self.tableItems.length && roleCheck('Member')) {
            self.goToHome()
          }
        }, function() {});
      },
      getUniqueCategories(object) {
        let categories = []
        // Loop through all data objects in third party
        if (object.data_objects && object.data_objects.length) {
          _.forEach(object.data_objects, function (item) {
            categories.push(item.classification)
          })
          // Return unique array of categories in data objects
          return _.uniq(categories);
        }
      },
      pageChanged(page) {
        this.loadContent(page)
      },
      perPageChanged(perPage) {
        let self = this;
        // Load content when page changed listener is triggered
        this.loadContent(1, perPage.value)
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadContent(1, self.selectedPerPage)
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.loadContracts()
        this.$refs['delete-third-party'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-third-party'].hide()
      },
      showDataObjectsModal(object) {
        this.selectedTP = object
        this.$refs['view-data-objects'].show()
      },
      hideDataObjectsModal() {
        this.selectedTP = {}
        this.$refs['view-data-objects'].hide()
      },
      confirmDeleteTP(object) {
        let self = this;
        piincHttp.delete('vendors/' + object.slug).then(function(response) {
          self.hideConfirmDeleteModal();
          self.loadContent(self.currentPage);
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('maintenance.third_parties.vendors.delete.success'))
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      loadContracts() {
        let self = this
        piincHttp.get('third-parties', { params:
          {
            per_page: 50,
            vendor: this.itemToDelete.id,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.contracts = response.data.items;
          self.loading = false;
        }, function() {});
      },
    },
    beforeRouteLeave(to, from, next) {
      let object = {
        type: 'vendors',
        filters: this.filters
      }
      this.$store.commit('storeActiveFilters', object);
      this.$store.commit('storePerPage', this.selectedPerPage);
      next()
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        if (this.getFilters.vendors) {
          this.filters = this.getFilters.vendors
        }
        this.loadContent()
      }
      this.loadGuides('vendor_list_page');
    }
  }
</script>
