<template>
  <span>
    <span class="btn btn-edit" qid="edit-tp-modal" @click="showEditTPModal(object)">
      <font-awesome-icon icon="pen"/>
    </span>

    <!-- Edit vendor modal template -->
    <b-modal ref="edit-third-party" hide-footer>
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('maintenance.third_parties.vendors.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-tp-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="editTPFormSubmit" qid="edit-tp-form">
        <div class="form-container">
          <b-form-group>
            <span class="align-middle mr-4">
              <img v-bind:src="getAvatar"
                   class="rounded-circle"
                   width="120"
                   height="120"
                   qid="profile-photo"
              >
            </span>
            <span class="align-middle">
              <label class="btn btn-secondary cursor-pointer pl-2 pr-2">
                <input type="file"
                       name="fileupload"
                       value="fileupload"
                       id="file"
                       @change="onFileChange"
                >
                <span>
                  <font-awesome-icon icon="cloud-upload-alt" />
                  {{ $t('maintenance.third_parties.vendors.upload_picture') }}
                </span>
              </label>
            </span>
          </b-form-group>
          <b-form-group>
            <label qid="edit-tp-name-label">
              {{ $t('maintenance.third_parties.create.name_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="edit-tp-form-name"
              type="text"
              v-model="tpForm.name"
              :maxLength="255"
              required
              :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditTPModal"
                    qid="edit-tp-cancel-button"
          >
            {{ $t('maintenance.third_parties.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="edit-tp-submit-button"
                    v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('maintenance.third_parties.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Crop image modal -->
    <b-modal ref="crop-image" hide-footer size="lg">
      <div class="d-block text-center">
        <h4 qid="edit-profile-modal-title">
          {{ $t('Crop image') }}
        </h4>
      </div>
      <div class="form-container" style="max-height: 600px;">
        <img v-bind:src="imageToCrop"
             qid="profile-photo"
             id="crop"
        >
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideCropModal"
                  qid="edit-profile-cancel-button"
        >
          {{ $t('profile.password.cancel') }}
        </b-button>
        <b-button type="submit"
                  class="mt-3 ml-3"
                  variant="success"
                  id="crop-button"
                  :disabled="buttonDisabled"
                  qid="edit-profile-submit-button"
        >
          <span v-if="buttonDisabled" class="mr-1">
            <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
          </span>
          {{ $t('profile.password.submit') }}
        </b-button>
      </div>
    </b-modal>

  </span>

</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';

  import VueCropper from 'vue-cropperjs';
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    name: 'VendorEditForm',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.third_parties.title'),
            to: '/contracts'
          },
          {
            text: this.$t('maintenance.third_parties.vendors.title'),
            to: '/third-parties'
          }
        ],
        thirdParty: {},
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        showFullDescription: false,
        showFullContact: false,
        itemToDelete: {},
        tpForm: {
          name: '',
          description: '',
          file: {}
        },
        addressForm: {
          company_number: '',
          address: '',
          city: '',
          state: '',
          postal_code: '',
          country: '',
        },
        showForm: false,
        error: '',
        types: [],
        typeInput: '',
        buttonDisabled: false,
        showDescriptionInput: false,
        processEditDescription: '',
        showContactInput: false,
        processEditContact: '',
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        tabIndex: 0,
        myGuides: [],
        selectedTags: [],
        tags: [],
        users: [],
        newTags: [],
        tableItems: [],
        loading: false,
        selectedDO: [],
        selectedUsers: [],
        selectedDataObject: {},
        viewDataObjectActivitiesModal: false,
        selectedDataObjectActivities: [],
        showHistoryTab: false,
        showRiskTab: false,
        showDataObjectDescription: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        keywordValue: '',
        selectedSort: '',
        selectedDirection: '',
        myTasks: [],
        selectedItem: {},
        showTagsModal: false,
        showUsersModal: false,
        viewDataModal: false,
        showAssessmentsTab: false,
        imageToCrop: '',
        avatar: '',
        avatarLarge: '',
        croppedBlob: {},
        countries: [],
        selectedCountry: []
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      edit: {
        type: Boolean,
      }
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getAvatar(){
        if (this.avatarLarge) {
          return this.avatarLarge;
        } else {
          return require('@/assets/tp-logo.png');
        }
      },
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
      },
      object: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      showEditTPModal(object) {
        let self = this;
        this.error = ''
        this.tpForm.slug = object.slug;
        this.tpForm.name = object.name;
        self.avatar = object.logo
        self.avatarLarge = object.logo
        this.$refs['edit-third-party'].show()
      },
      hideEditTPModal() {
        this.$refs['edit-third-party'].hide()
      },
      editTPFormSubmit(evt) {
        let sizeError = false;
        var moment = require('moment');
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        var formData = new FormData();

        if (this.croppedBlob.size > 10485760) {
          sizeError = true;
        }

        formData.append("logo", this.croppedBlob);
        formData.append("name", this.tpForm.name);
        formData.append("_method", 'put');

        if (sizeError) {
          self.error = self.$t('system.file_size')
        } else {
          this.buttonDisabled = true;
          piincHttp.post('vendors/' + self.object.slug, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.hideEditTPModal();
            self.buttonDisabled = false;
            self.$emit('reloadContent', response.data);
            self.$toastr('success', self.$t('maintenance.third_parties.vendors.update.success'))
          }, function(error) {
            self.buttonDisabled = false;
            self.tpForm.error = error.data.message;
            if (error.data.errors.avatar) {
              self.tpForm.error += ' ' + error.data.errors.avatar[0]
            }
            alert(self.tpForm.error)
          })
        }

      },
      showCropModal() {
        let self = this

        this.$refs['crop-image'].show()
        this.buttonDisabled = false;

        setTimeout(function(){
          const image = document.getElementById('crop');
          const cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 2,
            crop(event) {},
          });

          document.getElementById('crop-button').addEventListener('click', function(){
            cropper.getCroppedCanvas().toBlob(function (blob) {

              self.avatar = URL.createObjectURL(blob);
              self.avatarLarge = URL.createObjectURL(blob);
              self.imageToCrop = URL.createObjectURL(blob);
              self.croppedBlob = blob

              self.hideCropModal()

            });
          })
        }, 500);

      },
      hideCropModal() {
        this.$refs['crop-image'].hide()
      },
      onFileChange(file) {
        this.tpForm.file = file;
        const avatar = file.target.files[0];
        this.avatar = URL.createObjectURL(avatar);
        this.avatarLarge = URL.createObjectURL(avatar);
        this.imageToCrop = URL.createObjectURL(avatar);

        this.showCropModal()

      },
    },
    components: {
      Avatar
    },
    created () {
      this.editParam = this.edit;
      let self = this;
      if (this.object && this.object.id) {
        if (this.editParam && !this.modalShown) {
          setTimeout(function(){
            self.showEditPModal(self.object)
            self.modalShown = true;
          }, 500);
        }
      }
    }
  }
</script>
